import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import auth from "./auth";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  // const auth = null; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  // return auth ? <Component /> : <Navigate to="/login" />;
  if (auth.isAuthenticated()) {
    if (path === "/") {
      return <Navigate to="/dashboard" />;
    }
    if (
      path === "/sitesandenquiries" ||
      path === "/createcallofforder" ||
      path === "/transportonly" ||
      path === "/callofforder"
    ) {
      if (auth.isAllowed()) {
        return <Component />;
      }
      return <Navigate to="/dashboard" />;
    }
    return <Component />;
  }
  return <Navigate to="/login" />;
};
export default PrivateRoute;

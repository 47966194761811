/**
 * TransportOnly Request creation/edit.
 * For documentation, please refer below link.
 * https://hesusstore.atlassian.net/wiki/spaces/HS/pages/1913225247/5.3.+Transport+only+form
 */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TransportOnlyFormDetails from "./components/TransportOnlyFormDetails";
import FormContainer from "../../shared/components/FormContainer";

import callOffOrderLogo from "../../assets/shared/call-off-order.svg";

import { rejectObj } from "../../shared/utils/methods";

import { getSiteMasterListData, getProductData } from "../../store/dataStore";
import { handleLogOut } from "../../store/loginData";

import {
  createTransportRequest,
  updateTransportRequest,
  createTransportRequestForm,
  handleTransportData,
} from "./TransportOnlyForm.actionCreators.js";

const TransportOnlyForm = (props) => {
  const {
    apiUnAuthenticated,
    userId,
    transportRequestData,
    newTransportRequestLoading,
    t,
  } = props;

  const [formMetaData, setFormMetaData] = useState({});
  const history = useNavigate();

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else if (countryId === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  // Check if user is logged in, otherwise logout.
  useEffect(() => {
    if (userId === null || apiUnAuthenticated) {
      props.handleLogOut();
      history("/login", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, apiUnAuthenticated]);

  // Check if mandatory data is filled in.
  useEffect(() => {
    const requiredKeyObj = rejectObj(transportRequestData, [
      "days",
      "comment",
      "address_destination",
      "address",
    ]);
    requiredKeyObj.selected_days = JSON.stringify(requiredKeyObj.selected_days);
    const disabledBtn =
      Object.values(requiredKeyObj).filter((x) => x === "" || x === undefined)
        .length !== 0;

    setFormMetaData({ disabledBtn });
  }, [transportRequestData]);

  // For transport creation/edit
  useEffect(() => {
    if (transportRequestData.new && !transportRequestData.error) {
      localStorage.removeItem("transportData");
      localStorage.setItem("jobsiteId", transportRequestData.jobsite_id);
      props.getSiteMasterListData();
      history("/viewsite");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportRequestData]);

  // Send request to backend.
  const createTransportOnlyRequest = async () => {
    if (!transportRequestData.hasOwnProperty("editData")) {
      await props.createTransportRequest(transportRequestData);
    } else {
      await props.updateTransportRequest(transportRequestData);
    }
  };

  return (
    <FormContainer
      icon={callOffOrderLogo}
      isLoading={newTransportRequestLoading}
      onSubmit={createTransportOnlyRequest}
      isSubmitBtnDisabled={formMetaData.disabledBtn}
      title={t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TITLE")}
      description={t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESCRIPTION")}
      submitBtnLabel={t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_BUTTON_SAVE")}
      onCancel={() => {
        localStorage.removeItem("transportData");
        history(-1);
      }}
    >
      <TransportOnlyFormDetails
        selectedCountryId={parseInt(props.selectedCountryId, 10)}
        data={props.transportRequestData}
        weekDayList={props.weekDayList}
        productData={props.productData}
        getProductData={props.getProductData}
        createTransportRequestForm={props.createTransportRequestForm}
        handleTransportData={props.handleTransportData}
      />
    </FormContainer>
  );
};

TransportOnlyForm.propTypes = {
  userId: PropTypes.string,
  apiUnAuthenticated: PropTypes.bool,
  transportRequestData: PropTypes.object,
  newTransportRequestLoading: PropTypes.bool,
  selectedCountryId: PropTypes.number,
  weekDayList: PropTypes.array,
  productData: PropTypes.array,
  t: PropTypes.func.isRequired,
  createTransportRequest: PropTypes.func.isRequired,
  updateTransportRequest: PropTypes.func.isRequired,
  getSiteMasterListData: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  getProductData: PropTypes.func.isRequired,
  createTransportRequestForm: PropTypes.func.isRequired,
  handleTransportData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userId:
      localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : "",
    apiUnAuthenticated: state.transportOnlyForm.apiUnAuthenticated,
    transportRequestData: state.transportOnlyForm.transportOnlyData,
    newTransportRequestLoading: state.transportOnlyForm.newTransportRequestLoading,
    selectedCountryId:
      localStorage.getItem("countryId") !== ""
        ? parseInt(localStorage.getItem("countryId"), 10)
        : "",
    weekDayList: state.transportOnlyForm.weekDayList,
    productData: state.dataStore.productData,
  };
};

const mapDispatchToProps = {
  createTransportRequest: (payload) => createTransportRequest(payload),
  updateTransportRequest: (payload) => updateTransportRequest(payload),
  getSiteMasterListData: () => getSiteMasterListData(),
  handleLogOut: () => handleLogOut(),
  getProductData: (payload) => getProductData(payload),
  createTransportRequestForm: () => createTransportRequestForm(),
  handleTransportData: (payload) => handleTransportData(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TransportOnlyForm));

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import $ from "jquery";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import mobileLogo from "../../../assets/shared/ic-logo-mobile.svg";
import dashboardIcon from "../../../assets/shared/ic-dashboard.svg";
import sitesEnquiriesIcon from "../../../assets/shared/ic-sites-list.svg";
import callOffOrderIcon from "../../../assets/shared/ic-call-off-order-menu.svg";
import transportOnlyIcon from "../../../assets/shared/ic-transport-only-menu.svg";
import closeIcon from "../../../assets/shared/ic-close-black.svg";

import { handleLogOut } from "../../../store/loginData";
import { getCallOfOrderPopupVisible } from "../../../store/dataStore";

import { createCalloffOrderForm } from "../../../modules/CallOffOrderForm/CallOffOrderForm.actionCreators";

import { createTransportRequestForm } from "../../../modules/TransportOnlyForm/TransportOnlyForm.actionCreators";

import LanguageSelection from "../LanguageSelection";

import "./NavBar.scss";

const Navbar = (props) => {
  const {
    t,
    userDetails,
    isCallOfOrder,
    token,
    createCalloffOrder,
    createTransportRequest,
    logOut,
    getCallOfOrderPopupVisibility,
  } = props;
  const history = useNavigate();

  const [activeLink, setActiveLink] = useState("");

  const setActiveLinkName = () => {
    setActiveLink(window.location.pathname);
  };

  useEffect(() => {
    setActiveLinkName();
    $(() => {
      const $sidebar = $("#sidebar");
      const $sidebarAndcontent = $("#sidebar, #content");

      $("#mobileMenu").on("click", () => {
        $sidebarAndcontent.toggleClass("active");
        $(".collapse.in").toggleClass("in");
        $("a[aria-expanded=true]").attr("aria-expanded", "false");
      });

      $(".sidebar__mobile-header-close").on("click", () => {
        $sidebarAndcontent.removeClass("active");
      });

      $sidebar.on("mouseover", () => {
        if (!$sidebarAndcontent.hasClass("show")) {
          $sidebarAndcontent.addClass("active");
        }
      });

      $sidebar.on("mouseout", () => {
        if (!$sidebarAndcontent.hasClass("show")) {
          $sidebarAndcontent.removeClass("active");
        }
      });
    });
  }, []);

  useEffect(() => {
    if (userDetails === null) {
      logOut(token);
      history("/login", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleNavItemClick = (e) => {
    if (e.target.dataset.link === "/createcallofforder") {
      ReactGA.pageview("/createcallofforder");
      if (isCallOfOrder === 0) {
        getCallOfOrderPopupVisibility(true);
        return true;
      }
      createCalloffOrder();
    }
    $("#sidebar, #content").removeClass("active");
    if (e.target.dataset.link === "/transportonly") {
      ReactGA.pageview("/transportonly");
      createTransportRequest();
    }
    if (e.target.dataset.link === "/login") {
      logOut(token);
    }
    setActiveLink(e.target.dataset.link);
    history(e.target.dataset.link);
  };

  return (
    <nav id="sidebar">
      <div className="sidebar__mobile-header">
        <div
          data-link="/dashboard"
          onClick={handleNavItemClick}
          role="button"
          tabIndex="0"
        >
          <img src={mobileLogo} alt="Logo icon" data-link="/dashboard" />
        </div>
        <img
          src={closeIcon}
          alt="Close icon"
          className="sidebar__mobile-header-close"
        />
      </div>
      <ul className="sidebar__menu-list">
        <li data-link="/dashboard" onClick={handleNavItemClick}>
          <div
            className={activeLink === "/dashboard" ? "nav-link active" : "nav-link"}
            data-link="/dashboard"
          >
            <img
              src={dashboardIcon}
              width="18"
              height="18"
              alt="logo"
              data-link="/dashboard"
            />
            <span className="menu-pad" data-link="/dashboard">
              {t("CLIENT_PLATFORM_NAVIGATION_MENU_DASHBOARD")}
            </span>
          </div>
        </li>
        {parseInt(userDetails.status, 10) === 1 && (
          <li data-link="/sitesandenquiries" onClick={handleNavItemClick}>
            <div
              className={
                activeLink === "/sitesandenquiries" ? "nav-link active" : "nav-link"
              }
              data-link="/sitesandenquiries"
            >
              <img
                src={sitesEnquiriesIcon}
                width="21"
                height="21"
                alt="logo"
                data-link="/sitesandenquiries"
              />
              <span className="menu-pad" data-link="/sitesandenquiries">
                {t("CLIENT_PLATFORM_NAVIGATION_MENU_SITE_LISTING")}
              </span>
            </div>
          </li>
        )}
        {parseInt(userDetails.status, 10) === 1 && (
          <li data-link="/createcallofforder" onClick={handleNavItemClick}>
            <div
              className={
                activeLink === "/createcallofforder" ? "nav-link active" : "nav-link"
              }
              data-link="/createcallofforder"
            >
              <img
                src={callOffOrderIcon}
                width="24"
                height="24"
                alt="logo"
                data-link="/createcallofforder"
              />
              <span className="menu-pad" data-link="/createcallofforder">
                {t("CLIENT_PLATFORM_NAVIGATION_MENU_CALL_OFF_ORDER")}
              </span>
            </div>
          </li>
        )}
        {parseInt(userDetails.status, 10) === 1 && (
          <li data-link="/transportonly" onClick={handleNavItemClick}>
            <div
              className={
                activeLink === "/transportonly" ? "nav-link active" : "nav-link"
              }
              data-link="/transportonly"
            >
              <img
                src={transportOnlyIcon}
                width="22"
                height="22"
                alt="logo"
                data-link="/transportonly"
              />
              <span className="menu-pad" data-link="/transportonly">
                {t("CLIENT_PLATFORM_NAVIGATION_MENU_TRANSPORT_ONLY_REQUEST")}
              </span>
            </div>
          </li>
        )}
        <li
          className="show-desk-menus"
          data-link="/myaccount"
          onClick={handleNavItemClick}
        >
          <div className="nav-link" data-link="/myaccount">
            <button
              className="my-account-btn dropdown-toggle"
              id="profileDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-link="/myaccount"
              type="button"
            >
              {userDetails && userDetails.first_name && (
                <span style={{ fontSize: "13px", margin: "-2px" }}>
                  {userDetails.first_name ? userDetails.first_name.split("")[0] : ""}
                  {userDetails.last_name ? userDetails.last_name.split("")[0] : ""}
                </span>
              )}
              {!userDetails.first_name && !userDetails.last_name && (
                <i className="fa fa-user default-user" aria-hidden="true" />
              )}
            </button>
            <span className="menu-pad" data-link="/myaccount">
              {t("CLIENT_PLATFORM_NAVIGATION_MENU_MY_ACCOUNT")}
            </span>
          </div>
        </li>
        <li
          className="show-desk-menus"
          data-link="/login"
          onClick={handleNavItemClick}
        >
          <div className="nav-link" data-link="/login">
            <i
              className="fa fa-sign-out pr-1"
              aria-hidden="true"
              data-link="/login"
            />
            <span className="menu-pad" data-link="/login">
              {t("CLIENT_PLATFORM_NAVIGATION_MENU_LOGOUT")}
            </span>
          </div>
        </li>
      </ul>
      <ul className="show-desk-menus bottom sidebar__menu-list components position-absolute w-100 m-0">
        <li className="show-desk-menus">
          <LanguageSelection divClassName="translate dropdown float-right" />
        </li>
      </ul>
      <ul className="show-mobile-menus bottom sidebar__menu-list components position-absolute w-100 m-0">
        <li>
          <NavLink to="/myaccount" className="active">
            <button
              className="my-account-bottom fontLato14 rounded-circle dropdown-toggle"
              id="profileDropdown"
              aria-haspopup="true"
              aria-expanded="false"
              type="button"
            >
              {userDetails && userDetails.first_name && (
                <span style={{ fontSize: "13px", margin: "-2px" }}>
                  {userDetails.first_name ? userDetails.first_name.split("")[0] : ""}
                  {userDetails.last_name ? userDetails.last_name.split("")[0] : ""}
                </span>
              )}
              {!userDetails.first_name && !userDetails.last_name && (
                <i className="fa fa-user default-user" aria-hidden="true" />
              )}
            </button>
            <span className="menu-pad hide-text">
              {t("CLIENT_PLATFORM_NAVIGATION_MENU_MY_ACCOUNT")}
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/login" className="active" onClick={() => logOut(token)}>
            <i className="fa fa-sign-out" aria-hidden="true" />
            <span className="menu-pad hide-text">
              {t("CLIENT_PLATFORM_NAVIGATION_MENU_LOGOUT")}
            </span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

Navbar.propTypes = {
  token: PropTypes.string.isRequired,
  userDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isCallOfOrder: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  createTransportRequest: PropTypes.func.isRequired,
  createCalloffOrder: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  getCallOfOrderPopupVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: localStorage.getItem("token") !== "" ? localStorage.getItem("token") : "",
    userDetails:
      JSON.parse(localStorage.getItem("userInfo")) !== {}
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "",
    isCallOfOrder: state.dashboard.isCallOfOrder,
    isCallOfOrderPopupVisible: state.dataStore.isCallOfOrderPopupVisible,
  };
};

const mapDispatchToProps = {
  createTransportRequest: () => createTransportRequestForm(),
  createCalloffOrder: () => createCalloffOrderForm(),
  logOut: (payload) => handleLogOut(payload),
  getCallOfOrderPopupVisibility: (payload) => getCallOfOrderPopupVisible(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Navbar));

import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Navigate,
  Routes as ReactRoutes,
} from "react-router-dom";

import LoginHome from "../modules/Login/Login.screen";
import DashboardHome from "../modules/Dashboard/Dashboard.screen";
import SiteOverview from "../modules/SiteOverview/SiteOverview.screen";
import InquiryForm from "../modules/InquiryForm/InquiryForm.screen";
import MyAccount from "../modules/MyAccount/MyAccount.screen";
import OnboardingV3 from "../modules/OnboardingV3/OnboardingV3.screen.withCaptcha";
import JobSiteForm from "../modules/JobsiteForm/JobsiteForm.screen";
import TransportOnlyForm from "../modules/TransportOnlyForm/TransportOnlyForm.screen";
import SitesList from "../modules/SitesList/SitesList.screen";
import CallOffOrder from "../modules/CallOffOrder/CallOffOrder.screen";
import CallOffOrderForm from "../modules/CallOffOrderForm/CallOffOrderForm.screen";
import PageNotFound from "../modules/PageNotFound/PageNotFound.screen";

import ProtectedRoute from "./ProtectedRoute";
import PrivateRoute from "./PrivateRoute";

const Routes = () => (
  <Router>
    <ReactRoutes>
      {/* <Route
        path={[("/login", "/forgotpassword")].map((p) => (
          <Route key={p} path={p} element={<LoginHome />} />
        ))}
      /> */}

      <Route path="/login" element={<LoginHome />} />
      <Route path="/forgotpassword" element={<LoginHome />} />
      <Route
        path="/dashboard"
        element={<PrivateRoute component={DashboardHome} path="/dashboard" />}
      />
      <Route
        path="/"
        element={<PrivateRoute component={DashboardHome} path="/dashboard" />}
      />
      <Route
        path="/createenquiry"
        element={<PrivateRoute component={InquiryForm} path="/createenquiry" />}
      />
      <Route
        path="/createjobsite"
        element={<PrivateRoute component={JobSiteForm} path="/JobSiteForm" />}
      />
      <Route
        path="/transportonly"
        element={
          <PrivateRoute component={TransportOnlyForm} path="/transportonly" />
        }
      />
      <Route
        path="/myaccount"
        element={<PrivateRoute component={MyAccount} path="/myaccount" />}
      />
      <Route
        path="/viewsite"
        element={<PrivateRoute component={SiteOverview} path="/viewsite" />}
      />
      <Route
        path="/sitesandenquiries"
        element={<PrivateRoute component={SitesList} path="/sitesandenquiries" />}
      />
      <Route
        path="/createcallofforder"
        element={
          <PrivateRoute component={CallOffOrderForm} path="/createcallofforder" />
        }
      />
      <Route
        path="/callofforder"
        element={<PrivateRoute component={CallOffOrder} path="/callofforder" />}
      />
      <Route path="/onboarding" element={<OnboardingV3 />} />
      <Route path="/onboarding/:campaign_code" element={<OnboardingV3 />} />

      <Route path="/fr/onboarding" element={<Navigate to="/onboarding" />} />
      <Route path="/sw/onboarding" element={<Navigate t o="/onboarding" />} />
      <Route path="/pl/onboarding" element={<Navigate to="/onboarding" />} />
      <Route path="/de/onboarding" element={<Navigate to="/onboarding" />} />
      <Route path="/uk/onboarding" element={<Navigate to="/onboarding" />} />
      <Route
        path="/fr/onboarding:campaign_code"
        element={<Navigate to="/onboarding" />}
      />
      <Route
        path="/sw/onboarding:campaign_code"
        element={<Navigate to="/onboarding" />}
      />
      <Route
        path="/uk/onboarding:campaign_code"
        element={<Navigate to="/onboarding" />}
      />
      <Route
        path="/pl/onboarding:campaign_code"
        element={<Navigate to="/onboarding" />}
      />
      <Route
        path="/de/onboarding:campaign_code"
        element={<Navigate to="/onboarding" />}
      />
      <Route path="*" element={<PageNotFound />} />
    </ReactRoutes>
  </Router>
);

export default Routes;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga";

import Header from "../Header";
import { prepareTabListData } from "../../utils/methods";
import TabContent from "../../baseUI/TabContent";
import { handleLogOut } from "../../../store/loginData";

import SiteHeader from "./components/SiteHeader";
import ActiveSites from "./components/ActiveSites";
import InActiveSites from "./components/InActiveSites";

import "./ViewSitesList.scss";

const ViewSitesList = (props) => {
  const { userId } = props;
  const tabData = [
    "CLIENT_PLATFORM_SITE_LIST_TAB_ACTIVE",
    "CLIENT_PLATFORM_SITE_LIST_TAB_INACTIVE",
  ];
  const preparedTabData = prepareTabListData(tabData);

  useEffect(() => {
    ReactGA.pageview("/sitesandenquiries");
    // delete intermediate request form data if stored.
    localStorage.removeItem("requestData");
    localStorage.removeItem("selectedJobsiteForRequest");
    localStorage.removeItem("requestService");
    localStorage.removeItem("requestCategory");
    localStorage.removeItem("requestSubCategory");
  }, []);

  useEffect(() => {
    if (userId === null) {
      props.handleLogOut();
      return <Navigate to="/login" />;
    }
  }, [userId, props]);

  return (
    <div className="container-fluid position-relative">
      <Header tabData={preparedTabData} />
      <SiteHeader />
      <TabContent
        className="tab-content mt-2 site-btn-max"
        id="view-page-pills-tabContent"
        data={preparedTabData}
        components={[<ActiveSites />, <InActiveSites />]}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId:
      localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : null,
  };
};

const mapDispatchToProps = {
  handleLogOut: () => handleLogOut(),
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewSitesList);

import React from "react";
import { useNavigate } from "react-router-dom";

import "./EmptyView.scss";

const EmptyView = (props) => {
  const {
    title,
    description,
    icon,
    link,
    buttonName,
    isBtnVisible,
    type,
    btnDisabled,
  } = props;
  const history = useNavigate();

  const titleStyle =
    type !== undefined && type === "invoice" ? "invisible" : "no-sites-title";
  const btnStyle = btnDisabled
    ? "btn btn-primary btn-blue font-weight-normal btn-create-new-site disabled"
    : "btn btn-primary btn-blue font-weight-normal btn-create-new-site";

  const onAdd = () => {
    props.onBtnClick();
    history(link);
  };

  return (
    <div className="no-sites">
      <div className="site-icon-wrapper">
        <img className="site-icon" src={icon} alt="No Sites" />
      </div>
      <div className="no-sites-description-wrapper">
        <h5 className={titleStyle}>{title}</h5>
        <p className="no-sites-description">{description}</p>
        {isBtnVisible && (
          <button className={btnStyle} onClick={onAdd} disabled={btnDisabled}>
            {buttonName}
          </button>
        )}
      </div>
    </div>
  );
};

export default EmptyView;

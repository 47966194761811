import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import PropTypes from "prop-types";

import baseURL from "../../../../shared/utils/constant";

import SectionHeader from "../../../../shared/components/SectionHeader";
import EmptyView from "../../../../shared/components/EmptyView";
import ErrorPopUp from "../../../../shared/baseUI/ErrorPopUp";

import { getQuoteStatusList } from "../../../../shared/utils/methods";

import quoteIcon from "../../../../assets/shared/ic-quote.svg";

import "./MyQuotes.scss";

const MyQuotes = (props) => {
  const { quotes, t } = props;
  const [showErrorPopup, setErrorPopupvisibility] = useState(false);
  const [apiError, setApiError] = useState("");

  const quoteStatus = getQuoteStatusList();

  const axiosInstance = (token) => {
    return axios.create({
      baseURL,
      timeout: 36000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const downloadPDFDoc = (id) => {
    setErrorPopupvisibility(false);
    setApiError("");
    axiosInstance(localStorage.getItem("token"))
      .get(`${baseURL}/documents/download/${id}`)
      .then((res) => {
        const { fileName } = res.data;
        fetch(res.data.data)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((err) => {
            setErrorPopupvisibility(true);
            setApiError(
              err.response?.data?.message ? t(err.response?.data?.message) : ""
            );
          });
      })
      .catch((err) => {
        setErrorPopupvisibility(true);
        setApiError(
          err.response?.data?.message ? t(err.response?.data?.message) : ""
        );
      });
  };

  const renderQuotes = () => {
    return (
      <ul className="dashboard-quote-list">
        {quotes
          .filter((item, i) => i < 5)
          .map((item, i) => {
            return (
              <li key={item.id} className="quote-item">
                <img className="quote-img" src={quoteIcon} alt="Construction Site" />
                <div className="quote-info">
                  <div className="quote-title-wrapper">
                    <div className="quote-name-link-wrapper">
                      <h5
                        className="quote-name"
                        onClick={() => {
                          downloadPDFDoc(item.documents[0]?.id);
                        }}
                      >
                        {item.name}
                      </h5>
                      <div
                        className="quote-link"
                        onClick={() => {
                          downloadPDFDoc(item.documents[0]?.id);
                        }}
                      >
                        {`#${item.id}`}
                      </div>
                    </div>
                    <span
                      className={
                        parseInt(item.status, 10) === 2
                          ? "quote-status available"
                          : parseInt(item.status, 10) === 3
                          ? "quote-status validated"
                          : "quote-status"
                      }
                    >
                      {t(quoteStatus[item.status])}
                    </span>
                  </div>
                  <div className="quote-details">
                    <p className="jobsite-name">{item.jobsite_name}</p>
                    {item.enquiry_count > 0 && (
                      <span className="enquiry-count">
                        {" - "}
                        {`${item.enquiry_count} ${t(
                          "CLIENT_PLATFORM_DASHBOARD_QUOTES_INQUIRY_COUNT"
                        )} `}
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    );
  };

  const closePopup = () => {
    setErrorPopupvisibility(false);
  };

  return (
    <section className="my-quotes">
      {showErrorPopup && (
        <ErrorPopUp
          title={t("KICK_OFF_ERROR_BOX_TITLE")}
          btnText={t("KICK_OFF_ERROR_BOX_OK")}
          message={apiError}
          onOkClick={closePopup}
        />
      )}
      <SectionHeader
        title={t("CLIENT_PLATFORM_DASHBOARD_QUOTES_SECTION_TITLE")}
        link=""
        viewAllTitle={t("CLIENT_PLATFORM_DASHBOARD_SITES_BUTTON_ALL_SITES")}
        isViewAllVisible={false}
      />
      {quotes.length === 0 && (
        <EmptyView
          link="/createjobsite"
          description={t("CLIENT_PLATFORM_DASHBOARD_QUOTES_NO_QUOTE_DESCRIPTION")}
          title={t("CLIENT_PLATFORM_DASHBOARD_QUOTES_NO_QUOTE_TITLE")}
          icon={quoteIcon}
          buttonName={t("")}
          isBtnVisible={false}
          onBtnClick={() => {}}
        />
      )}
      {quotes.length > 0 && renderQuotes()}
    </section>
  );
};

MyQuotes.propTypes = {
  quotes: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(MyQuotes);

import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

import SectionHeader from "../../../../shared/components/SectionHeader";
import JobsiteTile from "../../../../shared/components/JobsiteTile";

import requestQuotationIcon from "../../../../assets/shared/ic-request-quotation.svg";
import sitelogo from "../../../../assets/shared/ic-lead-site.svg";

import {
  TO_BE_PROCESSED_LEADS,
  TO_BE_PROCESS_COMPLETE_LEADS,
} from "../../../../shared/utils/constant.js";

import "./MyRequestsQuotation.scss";

const MyRequestsQuotation = (props) => {
  const { t, requestQuotations } = props;
  const history = useNavigate();

  const renderRequestQuotations = () => {
    return (
      <ul className="requests_quotations__list">
        {requestQuotations
          .filter((item, i) => i < 5)
          .map((item, i) => {
            if (item.status === TO_BE_PROCESSED_LEADS) {
              return (
                <li
                  key={item.id}
                  className="requests_quotations__item"
                  onClick={(e) => props.viewLead(item.id)}
                >
                  <img
                    className="requests_quotations__quotation-icon"
                    src={requestQuotationIcon}
                    alt="Site Enquiry"
                  />
                  <div className="requests_quotations__quotation-details-wrapper">
                    {item.address && item.postal && item.city && (
                      <div className="requests_quotations__address-wrapper">
                        <span className="requests_quotations__address">
                          {parseInt(item.country_id, 10) !== 1 &&
                            `${item.address} ${item.postal} ${item.city}`}
                          {parseInt(item.country_id, 10) === 1 &&
                            `${item.address}, ${item.city}, ${item.postal}`}
                        </span>
                      </div>
                    )}
                    <div className="requests_quotations__product-wrapper">
                      <div className="requests_quotations__product">
                        {item.product_name && item.product_name !== "Unknown"
                          ? item.product_name
                          : t("CLIENT_PLATFORM_REQUEST_TYPE_UNKNOWN")}
                      </div>
                    </div>
                    <p className="requests_quotations__product-description">
                      <span>
                        {item.service_id === 1
                          ? t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_REMOVAL")
                          : t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_SUPPLY")}
                        &nbsp;:
                      </span>
                      <span className="requests_quotations__category-info">
                        {t(item.category_name)} - {t(item.sub_category_name)}
                      </span>
                    </p>
                    <p className="requests_quotations__quantity-wrapper">
                      <span>
                        {`${t("CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRY_QUANTITY")} : `}
                      </span>
                      <span className="requests_quotations__quantity">{`${
                        item.quantity
                      } ${item.unit ? t(item.unit) : ""}`}</span>
                    </p>
                  </div>
                </li>
              );
            }
            if (item.status === TO_BE_PROCESS_COMPLETE_LEADS) {
              return (
                <li
                  key={item.id}
                  onClick={() => {
                    props.viewJobSite(item.id);
                    history("/viewsite");
                  }}
                >
                  <JobsiteTile
                    {...item}
                    requests_count={item.request_count}
                    sitelogo={sitelogo}
                  />
                </li>
              );
            }
            return null;
          })}
      </ul>
    );
  };

  return (
    <section className="my-request-quotations">
      <SectionHeader
        title={t("CLIENT_PLATFORM_DASHBOARD_LEADS_SECTION_TITLE")}
        link="/sitesandenquiries"
        viewAllTitle={t("")}
        isViewAllVisible={false}
      />
      {props.isLeadListLoading && (
        <>
          <div className="loader-item first">
            <Skeleton width={50} height={50} />
            <div className="loader-item-text">
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={100} />
            </div>
          </div>
        </>
      )}
      {requestQuotations.length > 0 &&
        !props.isLeadListLoading &&
        renderRequestQuotations()}
    </section>
  );
};

MyRequestsQuotation.propTypes = {
  requestQuotations: PropTypes.array.isRequired,
  isLeadListLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  viewLead: PropTypes.func.isRequired,
  viewJobSite: PropTypes.func.isRequired,
};

export default withTranslation()(MyRequestsQuotation);

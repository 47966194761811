import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import PersonIcon from "../../../../assets/shared/dashboard-person-icon.svg";

import {
  ACTIVE_USER,
  PENDING_USER,
  TO_BE_PROCESSED_LEADS,
  TO_BE_PROCESS_COMPLETE_LEADS,
} from "../../../../shared/utils/constant";

import "./ContextualAssistant.scss";

const ContextualAssistant = (props) => {
  const {
    t,
    userDetails,
    quoteData,
    siteData,
    enquiryData,
    isCallOfOrder,
    leadList,
  } = props;

  const history = useNavigate();
  const AVAILABLE_QUOTE_STATUS = 2;

  const getAvailableQuote = () => {
    return quoteData.find(
      (quote) => parseInt(quote.status, 10) === AVAILABLE_QUOTE_STATUS
    );
  };
  /**
   * Find the list of sites with empty requests.
   */
  const isEnquiryInSiteEmpty = (sites) => {
    const sitesWithEmptyRequests = sites.filter(
      (siteItem) => siteItem.requests_count === 0
    );
    if (sitesWithEmptyRequests.length > 0) {
      return true;
    }
    return false;
  };

  const isEmptySite = () => {
    return (
      userDetails.status === ACTIVE_USER &&
      siteData.length === 0 &&
      enquiryData.length === 0
    );
  };

  const isEmptyEnquiry = () => {
    return (
      userDetails.status === ACTIVE_USER &&
      siteData.length > 0 &&
      isEnquiryInSiteEmpty(siteData)
    );
  };

  const isReviewQuote = () => {
    const availableQuote = getAvailableQuote();
    if (
      userDetails.status === ACTIVE_USER &&
      siteData.length > 0 &&
      availableQuote
    ) {
      return true;
    }
    return false;
  };

  const isCallOfOrderMsg = () => {
    return (
      userDetails.status === ACTIVE_USER &&
      siteData.length > 0 &&
      enquiryData.length > 0 &&
      isCallOfOrder > 0
    );
  };

  const reviewQuote = () => {
    const availableQuote = getAvailableQuote();
    localStorage.setItem("jobsiteId", availableQuote.jobsite_id);
    history("/viewsite");
  };

  // At least one of the leads should be in state TO_BE_PROCESSED_LEADS
  const getToBeProcessedLeads = () => {
    const toBeProcessedLeads = leadList.filter(
      (lead) => parseInt(lead.status, 10) === TO_BE_PROCESSED_LEADS
    );
    return toBeProcessedLeads.length > 0;
  };

  // All leads should be in the state TO_BE_PROCESS_COMPLETE_LEADS
  const getProcessCompleteLeads = () => {
    const leadsFiltered = leadList.filter(
      (lead) => parseInt(lead.status, 10) === TO_BE_PROCESS_COMPLETE_LEADS
    );
    return leadsFiltered.length === leadList.length;
  };

  const getRecentToBeProcessedLeads = () => {
    const toBeProcessedLeads = leadList.filter(
      (lead) => parseInt(lead.status, 10) === TO_BE_PROCESSED_LEADS
    );
    return toBeProcessedLeads.length > 0 ? toBeProcessedLeads[0] : {};
  };

  const createLead = () => {
    const lead = getRecentToBeProcessedLeads();
    const openingTimeLocal = lead.opening_time ? lead.opening_time.split(":") : [];
    const openingTime =
      openingTimeLocal.length >= 2
        ? `${openingTimeLocal[0]}:${openingTimeLocal[1]}`
        : "";
    const closingTimeLocal = lead.closing_time ? lead.closing_time.split(":") : [];
    const closingTime =
      closingTimeLocal.length >= 2
        ? `${closingTimeLocal[0]}:${closingTimeLocal[1]}`
        : "";
    const dataLocal = {
      name: {
        value: lead.name,
      },
      phase_id: {
        value: lead.phase_id,
      },
      opening_time: {
        value: openingTime,
      },
      closing_time: {
        value: closingTime,
      },
      address: {
        value: lead.address ? lead.address : "",
      },
      city: {
        value: lead.city ? lead.city : "",
      },
      postal: {
        value: lead.postal ? lead.postal : "",
      },
      request_priority: {
        value: lead.request_priority,
      },
      is_call_for_tender: {
        value: lead.is_call_for_tender ? lead.is_call_for_tender : 0,
      },
      user_comment: {
        value: lead.user_comment,
      },
      start_date: {
        value: lead.start_date,
      },
      duration: {
        value: lead.duration,
      },
      latitude: {
        value: lead.latitude ? lead.latitude : "",
      },
      longitude: {
        value: lead.longitude ? lead.longitude : "",
      },
      "other-info-comment": {
        value: lead["other-info-comment"],
      },
    };

    localStorage.setItem("isExistingJobsiteEdit", JSON.stringify(false));

    localStorage.setItem("jobsiteEditData", JSON.stringify(dataLocal));
    props.createSite({ lead_id: lead.id, isLead: true });
    history("/createjobsite");
  };

  return (
    <div className="contextual-assistant__container">
      <div className="contextual-assistant__icon-wrapper">
        <img
          src={PersonIcon}
          className="contextual-assistant__icon"
          alt="user icon"
        />
      </div>
      <div className="contextual-assistant__details-wrapper">
        <h2 className="contextual-assistant__user-name">
          {userDetails.first_name
            ? `${t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_WELCOME")}, ${
                userDetails.first_name
              } !`
            : t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_WELCOME")}
        </h2>
        {isEmptySite() && (
          <>
            <p className="contextual-assistant__description">
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_NO_SITE")}
            </p>
            <NavLink
              to="/createjobsite"
              className="contextual-assistant__link"
              onClick={() => props.createSite()}
            >
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_CREATE_SITE")}
            </NavLink>
          </>
        )}

        {isEmptyEnquiry() && !isReviewQuote() && !isCallOfOrderMsg() && (
          <>
            <p className="contextual-assistant__description">
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_NO_INQUIRY")}
            </p>
            <NavLink
              to="/createenquiry"
              className="contextual-assistant__link"
              onClick={() => props.createEnquiry()}
            >
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_CREATE_INQUIRY")}
            </NavLink>
          </>
        )}
        {isReviewQuote() && !isCallOfOrderMsg() && (
          <>
            <p className="contextual-assistant__description">
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_QUOTE_AVAILABLE")}
            </p>
            <div
              className="contextual-assistant__link"
              onClick={() => reviewQuote()}
              role="button"
              tabIndex={0}
            >
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_REVIEW_QUOTE")}
            </div>
          </>
        )}
        {isCallOfOrderMsg() && (
          <>
            <p className="contextual-assistant__description">
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_CALL_OFF_ORDER")}
            </p>
            <NavLink
              to="/createcallofforder"
              className="contextual-assistant__link"
              onClick={() => props.createCalloffOrderForm()}
            >
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_CREATE_CALL_OFF_ORDER")}
            </NavLink>
          </>
        )}
        {userDetails.status === PENDING_USER && getToBeProcessedLeads() && (
          <>
            <p className="contextual-assistant__description">
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_LEAD_TO_COMPLETE")}
            </p>
            <div
              onClick={() => {
                createLead();
              }}
              className="contextual-assistant__link"
              role="button"
              tabIndex={0}
            >
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_CREATE_LEAD_TO_COMPLETE")}
            </div>
          </>
        )}
        {userDetails.status === PENDING_USER && getProcessCompleteLeads() && (
          <>
            <p className="contextual-assistant__description">
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_LEAD_ENQUIRY")}
            </p>
            <NavLink
              to="/createenquiry"
              onClick={() => props.createEnquiry()}
              className="contextual-assistant__link"
            >
              {t("CLIENT_PLATFORM_DASHBOARD_ASSISTANT_CREATE_LEAD_ENQUIRY")}
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

ContextualAssistant.propTypes = {
  userDetails: PropTypes.shape({
    first_name: PropTypes.string,
  }).isRequired,
  quoteData: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteData: PropTypes.arrayOf(PropTypes.object).isRequired,
  enquiryData: PropTypes.arrayOf(PropTypes.object).isRequired,
  leadList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCallOfOrder: PropTypes.number,
  createSite: PropTypes.func.isRequired,
  createEnquiry: PropTypes.func.isRequired,
  createCalloffOrderForm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ContextualAssistant);

import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import $ from "jquery";

import { handleLogOut } from "../../../store/loginData";

import logo from "../../../assets/shared/logo.png";
import sitelogo from "../../../assets/shared/site-icon.svg";

import LanguageSelection from "../LanguageSelection";
import TabList from "../../baseUI/TabList";

import "./Header.scss";

const Header = (props) => {
  const { token, tabData, jobSiteData, userDetails, t } = props;
  const { pathname } = window.location;
  const history = useNavigate();

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else if (countryId === "8") {
        lng = "ch";
      }
    }
    localStorage.setItem("langFlag", lng);
  }, []);

  useEffect(() => {
    if (!userDetails) {
      history("/login", { replace: true });
    }
  }, [userDetails, history]);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else if (countryId === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  const handleLogOutData = (tokenVal) => {
    props.handleLogOut(tokenVal);
    history("/login", { replace: true });
  };

  const sidebarCollapse = () => {
    $("#sidebar, #content").toggleClass("active");
    $(".collapse.in").toggleClass("in");
    $("a[aria-expanded=true]").attr("aria-expanded", "false");
    if ($("#sidebar, #content").hasClass("active")) {
      $("#sidebar, #content").addClass("show");
    } else {
      $("#sidebar, #content").removeClass("show");
    }
  };

  return (
    <>
      {userDetails && (
        <div className="row header-container" id="header">
          <nav className="navbar navbar-expand navbar-light mb-0">
            <div className="container-fluid">
              <div className="row header-row">
                {/* eslint-disable-next-line */}
                <button
                  onClick={sidebarCollapse}
                  type="button"
                  className="btn btn-info nav-btn pr-3"
                >
                  <i className="fa fa-align-justify" aria-hidden="true" />
                </button>
                <div className="header-title">
                  <img
                    src={pathname === "/viewsite" ? sitelogo : logo}
                    className="logo mr-3"
                    width="54"
                    height="54"
                    alt="logo"
                  />
                  {pathname === "/dashboard" && (
                    <h2 className="d-inline-block m-0">
                      {t("CLIENT_PLATFORM_DASHBOARD_HEADER_TITLE")}
                    </h2>
                  )}

                  {pathname === "/sitesandenquiries" && (
                    <div className="d-inline-block">
                      <h2 className="d-inline-block">{t("MY_CHANTIER")}</h2>
                      <TabList
                        className="view-page-tabs position-absolute"
                        id="view-page-pills-tab"
                        data={tabData}
                      />
                    </div>
                  )}
                  {pathname === "/callofforder" && (
                    <div className="d-inline-block">
                      <h2 className="d-inline-block">My call-off orders</h2>
                      <TabList
                        className="view-page-tabs position-absolute"
                        id="view-page-pills-tab"
                        data={tabData}
                      />
                    </div>
                  )}
                  {pathname === "/viewsite" && (
                    <div className="d-inline-block">
                      <h2 className="d-inline-block jobsite-title">
                        {jobSiteData.name}
                      </h2>
                      {jobSiteData.id && (
                        <span className="d-inline-block jobsite-id">
                          {`#${jobSiteData.id}`}
                        </span>
                      )}
                      <TabList
                        className="view-page-tabs position-absolute"
                        id="view-page-pills-tab"
                        data={tabData}
                      />
                    </div>
                  )}
                </div>
                <ul className="nav navbar-nav ml-auto d-none d-md-flex">
                  <li className="dropdown">
                    <button
                      className="my-account-btn fontLato14 rounded-circle mr-2 dropdown-toggle"
                      id="profileDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      type="button"
                    >
                      {Object.prototype.hasOwnProperty.call(
                        userDetails,
                        "first_name"
                      ) && (
                        <span>
                          {userDetails.first_name
                            ? userDetails.first_name.split("")[0]
                            : ""}
                          {userDetails.last_name
                            ? userDetails.last_name.split("")[0]
                            : ""}
                          {!userDetails.first_name && !userDetails.last_name && (
                            <i
                              className="fa fa-user default-user"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      )}
                    </button>
                    <div
                      className="signout dropdown-menu dropdown-menu-right text-nowrap "
                      aria-labelledby="profileDropdown"
                    >
                      <NavLink to="/myaccount" className="d-block px-2 py-1">
                        <i className="fa fa-user pr-1" aria-hidden="true" />{" "}
                        {t("CLIENT_PLATFORM_NAVIGATION_MENU_MY_ACCOUNT")}
                      </NavLink>
                      <button
                        className="d-block border-0 p-0 sign-out-btn px-2 
                    py-1 w-100 text-left"
                        onClick={() => handleLogOutData(token)}
                        type="button"
                      >
                        <i className="fa fa-sign-out pr-1" aria-hidden="true" />
                        {t("CLIENT_PLATFORM_NAVIGATION_MENU_LOGOUT")}
                      </button>
                    </div>
                  </li>
                  <li>
                    <LanguageSelection divClassName="translate dropdown float-right" />
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: localStorage.getItem("token") !== "" ? localStorage.getItem("token") : "",
    userDetails:
      JSON.parse(localStorage.getItem("userInfo")) !== {}
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "",
    jobSiteData: state.jobsiteOverview.jobSiteFormData,
  };
};

const mapDispatchToProps = {
  handleLogOut: (payload) => handleLogOut(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Header));

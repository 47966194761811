import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import JobsiteDetails from "./components/JobsiteDetails";
import FormContainer from "../../shared/components/FormContainer";

import { rejectObj } from "../../shared/utils/methods";

import siteLogo from "../../assets/shared/site-icon.svg";
import baseURL from "../../shared/utils/constant";

import { getCategoriesData } from "../../store/dataStore";
import {
  addNewJobSite,
  updateJobSite,
  handleSiteFormDetails,
  deleteDocument,
  getSiteMasterListData,
  setLeadViewStatus,
} from "./JobsiteForm.actionCreators.js";
import { editEnquiry } from "../InquiryForm/InquiryForm.actionCreators";

import { handleLogOut } from "../../store/loginData";

const JobSiteForm = (props) => {
  const { t, newJobsiteLoading, data, userId, editJobSiteStatus, lead } = props;

  const [isFormValid, setFormValidity] = useState(false);
  const [isJobsiteFormEdit, setFormEditStatus] = useState(false);
  const [countryId, setCountryId] = useState();

  const { pathname } = window.location;
  const history = useNavigate();

  useEffect(() => {
    const savedData = localStorage.getItem("jobsiteEditData");
    const isExistingJobsiteEdit = localStorage.getItem("isExistingJobsiteEdit");

    if (savedData && data.name === "") {
      const dataLocal = JSON.parse(savedData);
      const jobsiteData = { ...data };
      jobsiteData.name = dataLocal.name.value;
      jobsiteData.phase_id = dataLocal.phase_id.value;
      jobsiteData.opening_time = dataLocal.opening_time.value;
      jobsiteData.closing_time = dataLocal.closing_time.value;
      jobsiteData.address = dataLocal.address.value;
      jobsiteData.city = dataLocal.city.value;
      jobsiteData.postal = dataLocal.postal.value;
      jobsiteData.request_priority = dataLocal.request_priority.value;
      jobsiteData.is_call_for_tender = dataLocal.is_call_for_tender.value;
      jobsiteData.user_comment = dataLocal.user_comment.value;
      jobsiteData["other-info-comment"] = dataLocal["other-info-comment"]
        ? dataLocal["other-info-comment"].value
        : "";
      jobsiteData.start_date = dataLocal.start_date.value;
      jobsiteData.duration = dataLocal.duration.value;
      jobsiteData.end_date = dataLocal.duration.value;
      jobsiteData.latitude = dataLocal.latitude.value;
      jobsiteData.longitude = dataLocal.longitude.value;
      if (isExistingJobsiteEdit === "true") {
        jobsiteData.edit = true;
      }
      props.handleSiteFormDetails({ updatedData: jobsiteData });
    }

    return () => {
      localStorage.removeItem("jobsiteEditData");
      localStorage.removeItem("isExistingJobsiteEdit");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId === null) {
      props.handleLogOut();
      history("/login", { replace: true });
    }
  }, [userId, history, props]);

  useEffect(() => {
    if (data?.id && data?.error && !data?.error?.message) {
      // Jobsite creation.
      localStorage.setItem("jobsiteId", data.id);
      if (data.leadInfo) {
        if (pathname !== "/createenquiry") {
          const jobsiteSelected = {
            id: data.id,
            name: data.name,
            start_date: data.start_date,
            duration: data.duration,
          };
          props.editEnquiry({
            jobsite: jobsiteSelected,
            document: data.leadDocuments ? data.leadDocuments : [],
            related_contact: [],
            leadInfo: data.leadInfo,
          });
          history("/createenquiry");
        }
      } else {
        history("/viewsite");
      }
    }
  }, [data, pathname, history, props]);

  // For jobsite edit.
  useEffect(() => {
    if (data.name !== "" && editJobSiteStatus?.success) {
      localStorage.setItem("jobsiteId", data.id);
      props.getSiteMasterListData();
      history("/viewsite");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editJobSiteStatus, data.name]);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
        setCountryId(1);
      } else if (countryIdLocal === "2") {
        lng = "fr";
        setCountryId(2);
      } else if (countryIdLocal === "3") {
        lng = "pl";
        setCountryId(3);
      } else if (countryIdLocal === "6") {
        lng = "de";
        setCountryId(6);
      } else if (countryIdLocal === "8") {
        lng = "ch";
        setCountryId(8);
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  useEffect(() => {
    if (lead?.leadInfo?.id) {
      updateEnquiryData(lead.leadInfo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead]);

  const updateEnquiryData = (leadId) => {
    axios
      .post(
        `${baseURL}/leads/${leadId}`,
        {
          user_viewed: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token") !== ""
                ? localStorage.getItem("token")
                : ""
            }`,
          },
        }
      )
      .then(() => {
        props.setLeadViewStatus(true);
      });
  };

  const createJobSite = async () => {
    const { token } = props;
    const form = document.getElementById("job-siteform");
    if (data.lead_completed) {
      props.addNewJobSite({ token, data, form });
    } else if (!data.id || data.hasOwnProperty("related_contact")) {
      props.addNewJobSite({ token, data, form });
    } else {
      await props.updateJobSite({ token, data, form });
    }
  };

  const updateFormValidity = (isValid) => {
    setFormValidity(isValid);
  };

  const getDisableStatus = (formData) => {
    let disabledBtn;
    let requiredKeyObj;

    // If form is incomplete then disable the button.
    if (isJobsiteFormEdit && !isFormValid) return true;

    if (formData) {
      requiredKeyObj = rejectObj(formData, [
        "is_call_for_tender",
        "initialRow",
        "document",
        "user_comment",
        "comment",
        "other-info-comment",
        "latitude",
        "longitude",
      ]);

      disabledBtn =
        Object.values(requiredKeyObj).filter(
          (x) => x === "" || x === null || x === undefined
        ).length !== 0;
    }

    return disabledBtn;
  };

  const isJobsiteFormEdited = (isEdit) => {
    setFormEditStatus(isEdit);
  };

  return (
    <FormContainer
      icon={siteLogo}
      isLoading={newJobsiteLoading}
      onSubmit={createJobSite}
      isSubmitBtnDisabled={getDisableStatus(data)}
      title={t("CLIENT_PLATFORM_SITE_FORM_TITLE")}
      description={t("CLIENT_PLATFORM_SITE_FORM_DESCRIPTION")}
      submitBtnLabel={t("CLIENT_PLATFORM_SITE_FORM_BUTTON_SAVE")}
      onCancel={() => history(-1)}
    >
      <JobsiteDetails
        selectedCountryId={props.selectedCountryId}
        data={props.data}
        siteHrsOptions={props.siteHrsOptions}
        stage={props.stage}
        handleSiteFormDetails={props.handleSiteFormDetails}
        deleteDocument={props.deleteDocument}
        getCategoriesData={props.getCategoriesData}
        updateFormValidity={updateFormValidity}
        isJobsiteFormEdited={isJobsiteFormEdited}
        countryId={countryId}
      />
    </FormContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userId:
      localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : "",
    token: localStorage.getItem("token") !== "" ? localStorage.getItem("token") : "",
    data: state.jobsiteForm.jobSiteFormData,
    newJobsiteLoading: state.jobsiteForm.newJobsiteLoading,
    selectedCountryId:
      localStorage.getItem("countryId") !== ""
        ? parseInt(localStorage.getItem("countryId"), 10)
        : "",
    siteHrsOptions: state.jobsiteForm.siteHrsOptions,
    stage: state.dataStore.requestCategoriesData.stage,
    editJobSiteStatus: state.jobsiteForm.editJobSiteStatus,
    lead: state.dashboard.lead,
  };
};

const mapDispatchToProps = {
  addNewJobSite: (payload) => addNewJobSite(payload),
  updateJobSite: (payload) => updateJobSite(payload),
  editEnquiry: (payload) => editEnquiry(payload),
  handleSiteFormDetails: (payload) => handleSiteFormDetails(payload),
  deleteDocument: (payload) => deleteDocument(payload),
  getCategoriesData: () => getCategoriesData(),
  getSiteMasterListData: () => getSiteMasterListData(),
  handleLogOut: () => handleLogOut(),
  setLeadViewStatus: (payload) => setLeadViewStatus(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(JobSiteForm));
